<!-- 门店管理--分类列表 -->

<template>
  <div class="classification-container main-cnt">
    <!-- 分类列表 -->
    <div class="title">分类列表</div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="StoreApi.getClassesList" :columns="tableColumns" @edit="openDialog"
        @statusChange="changeClassificationStatus">
        <template #operate>
          <el-button type="primary" round @click="openDialog"
            v-if="authData.indexOf('n_097F72F36B7D38CA29ED94484C60') != -1">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增分类
          </el-button>
        </template>
      </common-table>
    </div>

    <!-- 新增、编辑分类 -->
    <w-dialog ref="dialogRef" class="vip-dialog" :title="ruleForm.poc_id ? '编辑分类' : '新增分类'" width="50%" btnWidth="140px"
      top="20vh" :confirmText="ruleForm.poc_id ? '确认编辑' : '确认新增'" @wConfirm="handleSure">
      <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="选择项目" prop="poc_pid">
              <el-select v-model="ruleForm.poc_pid" clearable placeholder="请选择项目">
                <el-option :label="item.p_name" :value="item.p_id" v-for="item in projectOptions" :key="item.p_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分类名称" prop="poc_name">
              <el-input v-model.trim="ruleForm.poc_name" placeholder="请输入分类名称" clearable maxlength="6"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, computed, watch } from "vue";
  import { BasicApi, StoreApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { useStore } from "vuex";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );

  // 项目下拉选项
  const projectOptions = ref([]);
  onMounted(() => {
    // 获取分类列表数据
    tableRef.value.tableLoad();
    // 获取项目选项数据
    BasicApi.projectSelect().then((res) => {
      if (res.Code === 200) {
        // 新增分类项目选项
        projectOptions.value = res.Data ? res.Data : [];
        // 筛选项目选项
        filters.value[0].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取项目数据失败！";
        ElMessage.error(msg);
      }
    });
  });
  // 筛选配置
  const filters = ref([
    {
      filterType: "select",
      name: "poc_pid",
      value: "",
      placeholder: "请选择项目",
      options: [],
      val: "p_id",
      lab: "p_name",
    },
    {
      filterType: "select",
      name: "poc_status",
      value: "",
      placeholder: "请选择分类状态",
      options: [{ poc_status: "1", s_name: "正常" }, { poc_status: "2", s_name: "禁用" }],
      val: "poc_status",
      lab: "s_name",
    },
    {
      filterType: "search",
      name: "keywords",
      value: "",
      placeholder: "请输入分类名称进行搜索",
    },
  ]);
  // 表格对象
  const tableRef = ref(null);
  // 表格配置数据
  const tableColumns = ref([
    {
      prop: "poc_id",
      label: "分类ID",
    },
    {
      prop: "poc_name",
      label: "商品分类名称",
    },
    {
      prop: "p_name",
      label: "所属项目",
      objKey: "m_realname",
      color: "--text-third-color",
    },
    {
      prop: "poc_status",
      type: "switch",
      label: "分类状态",  // 1正常 2禁用
      token: "n_3945B62859D3BF4CA13AD5ADB943",
    },
    {
      color: "--text-third-color",
      prop: "u_name",
      objKey: "u_name",
      label: "创建人",
    },
    {
      prop: "poc_ctime",
      label: "创建时间",
      color: "--text-third-color",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 100,
      bottons: [
        {
          name: "编辑",
          action: "edit",
          token: "n_93EB3876C21DE0307B44A12E1076",
        },
      ],
    },
  ]);
  /**
   * 
   * 分类状态事件
   * 
   * */
  function changeClassificationStatus(row) {
    let data = {
      poc_id: row.poc_id,
      poc_status: row.poc_status == 1 ? 2 : 1,  // 1正常 2禁用
    };
    StoreApi.setUpdateStatus(data).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("操作成功！");
        tableRef.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : "操作失败！";
        ElMessage.error(msg);
      }
    });
  }

  // 对话框对象
  const dialogRef = ref(null);
  // 表格对象
  const formRef = ref(null);
  // 表格数据
  const ruleForm = ref({
    poc_pid: "",
    poc_name: "",
  });
  // 验证表格内容不为空
  const rules = reactive({
    poc_pid: [{ required: true, message: "请选择项目", trigger: "chaneg" }],
    poc_name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
  });
  /**
   * 
   * 新增、编辑分类按钮
   * 
   * */
  const openDialog = (row) => {
    if (row.poc_id) {
      //编辑
      const data = JSON.parse(JSON.stringify(row));
      ruleForm.value = data;
    } else {
      // 新增
      ruleForm.value = {
        poc_pid: "",
        poc_name: "",
      };
    }
    dialogRef.value.show();
  };
  /**
   * 
   * 新增/编辑分类 确认提交
   * 
   * */
  const handleSure = () => {
    formRef.value.validate((valid) => {
      if (valid) {
        // 加载中
        dialogRef.value.isLoading = true;
        // 参数
        const data = JSON.parse(JSON.stringify(ruleForm.value));
        let parmas = {
          poc_pid: data.poc_pid,
          poc_name: data.poc_name,
        };
        let url = "";
        if (data.poc_id) {
          // 编辑
          url = "editClasses";
          parmas.poc_id = data.poc_id;
        } else {
          // 新增
          url = "addClasses";
        }
        // 接口
        StoreApi[url](parmas).then((res) => {
          dialogRef.value.isLoading = false;
          if (res.Code === 200) {
            ElMessage.success(`操作成功！`);
            dialogRef.value.close();
            tableRef.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : `操作失败！`;
            ElMessage.error(msg);
          }
        });
      }
    });
  };
</script>

<style lang="scss">
  .classification-container {
    font-family: "Source Han Sans CN";

    .content {
      padding: 15px 20px 20px;
    }

    .vip-dialog {
      .el-dialog {
        min-width: 600px;

        .el-dialog__body {
          padding: 30px;

          .el-divider--horizontal {
            margin: 10px 0;
          }
        }
      }
    }
  }
</style>